import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Bubble from '../Bubble';
import { connect } from 'react-redux';
import { updateTextInput } from '../../actions/text.action';


const useStyles = makeStyles( {
  root: {
    width: '65%',
    margin: 10
  },
  value: { textAlign: 'center' }
} );


function RangeSlider( props ) {
  const classes = useStyles();
  const [ value, setValue ] = React.useState( [ props.min, props.max ] );
  props.updateTextInput( {
    status: false,
    value: `${ props.min } - ${ props.max }`
  } );
  const handleChange = ( event, newValue ) => {
    setValue( newValue );
    props.updateTextInput( {
      status: false,
      value: `${ value[ 0 ] } - ${ value[ 1 ] }`
    } );
  };
  const change = ( e, index ) => {
    if ( index === 0 ) {
      props.updateTextInput( {
        status: false,
        value: `${ e } - ${ value[ 1 ] }`
      } );
    } else {
      props.updateTextInput( {
        status: false,
        value: `${ value[ 0 ] } - ${ e }`
      } );
    }
  };
  return (
    <div>
      <Bubble avatar={ props.avatar } text={ props.text || 'Select your Range' }/>

      <Slider
        getAriaValueText={ change }
        style={ { marginLeft: 40 } }
        className={ classes.root }
        value={ value }
        onChange={ handleChange }
        valueLabelDisplay="off"
        min={ props.min || 0 }
        max={ props.max || 100 }
        step={ props.step || 1 }
        color={ 'primary' }
      />
      <div style={ { display: 'flex' } }>
        <div style={ { marginLeft: 30 } }>
          { props.pre } <span style={ { color: 'var(--primary-theme-color)' } }>{ props.min }</span>
        </div>
        <div style={ { marginLeft: '60%' } }>
          { props.post } <span style={ { color: 'var(--primary-theme-color)' } }>{ props.max }</span>
        </div>
      </div>
    </div>
  );
}

export default connect( null, {
  updateTextInput
} )( RangeSlider );
