import { NEW_MESSAGE, UPDATE_MESSAGES, NEW_NOTIFICATION, LIVE_CHAT_NOTIFICATION, OLD_MESSAGES } from './actions';

export const newMessage = (payload) => ({
  type: NEW_MESSAGE,
  payload
});

export const oldMessages = (payload) => ({
  type: OLD_MESSAGES,
  payload
});

export const updateMessage = (payload) => ({
  type: UPDATE_MESSAGES,
  payload
});

export const newNotification = (payload) => ({
  type: NEW_NOTIFICATION,
  payload
});

export const liveChatNotification = (payload) => ({
  type: LIVE_CHAT_NOTIFICATION,
  payload
});

export const loadInitialMessages = () => ({ type: 'LOAD_INITIAL_MESSAGES' });
