import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
  },
  contentCenter: {
    width: '100%',
    margin: '0 auto'
  },

  content: {
    marginTop: '0',
    color: '#707070',
    fontSize: '14px',
    textAlign: 'center',
  }
});

export default function Comment(props) {
  const classes = useStyles();

  return (
    <div className={classes.contentCenter}>
      <p className={classes.content}>{props.text}</p>
    </div>

  );
}
