import React from 'react';
import { ThemeProvider, withStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typing from '../components/Typing';
import SkipBack from '../components/SkipBack';
import TextField from '../components/TextInput';
import renderComponent from '../utilities/render-component';
import { STORE } from '../store';
import { getDeviceDetails, getIpDetails, scroll } from '../utilities';
import { updateMeta } from '../actions/meta.action';
import { makeSocketConnection, updateMeta as updateMetaToSocket } from '../utilities/socket';
import { loadConfig, loadDesign, loadFlow } from '../actions/flow.action';
import { updateEnvironment } from '../actions/environment.action';
import { loadInitialMessages, oldMessages } from '../actions/message.action';
import { loadInitialText } from '../actions/text.action';
import loaderGif from '../assets/gif/loader.gif';
import { Network } from '../utilities/network';
import { encryptPayload } from '../utilities/save-message';

const styles = () => ({
  chatArea: {
    marginTop: 65,
    overflowY: 'auto',
    background: 'white;',
    marginBottom: 86,
    position: 'relative'
  },
  video: {
    width: 'auto !important',
    margin: 10
  }
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showElement: false,
      currentPath: window.location.pathname,
      start: false,
      showLoader: false,
      conversationStartedMessage: false,
      page: 1,
      theme: createTheme({
        typography: {
          fontFamily: ['Brandon Grotesque Light'],
          fontWeightRegular: '600',
          fontWeightLight: '600'
        },
        palette: {
          primary: {
            main: '#cc1d38'
          },
          secondary: {
            main: '#000000'
          },
          text: {
            primary: '#ffffff'
          }
        }
      })
    };
    localStorage.removeItem('agentId');
    window.chatAreaRef = React.createRef();
  }

  async componentDidMount() {
    // Check if window.parent.BotPenguinData exists
    if (!window.parent.BotPenguinData) {

      const path = window.location.pathname.split('/');

      // fetch(`https://api-dev-botpenguin.relinns.com.au/website-widget/${path[2]}/${path[1]}?uuid=${localStorage.getItem('BotPenguin_User_uuid')}`)
      fetch(`https://api.v7.botpenguin.com/website-widget/${path[2]}/${path[1]}?uuid=${localStorage.getItem('BotPenguin_User_uuid')}`)
        .then(res => res.json())
        .then((res) => {
          if (!res) {
            throw new Error('Bot Not Found');
          }

          const botInfo = { ...res.data, isLanding: true };

          // Setting window object of messenger to access data from it
          window.parent.BotPenguinData = botInfo;
          localStorage.setItem('BotPenguin_User_uuid', botInfo.uuid);
          localStorage.setItem('__BotPenguinUser', path[2]);
          localStorage.setItem('__BotPenguin', path[1]);

          STORE.dispatch(loadInitialMessages());
          STORE.dispatch(loadFlow());
          STORE.dispatch(loadDesign());
          STORE.dispatch(loadConfig());
          STORE.dispatch(loadInitialText());

          STORE.dispatch(updateEnvironment({
            _id: window.parent.BotPenguinData._id,
            _user: window.parent.BotPenguinData._user,
            activeQuestion: null,
            activeFlow: window.parent.BotPenguinData.flows[0].id,
            preview: window.parent.BotPenguinData.preview || false,
            embedded: window.parent.BotPenguinData.embedded || false,
            refresh: true,
            typing: false,
            pause: false,
            activeQuestionType: 'statement',
            skip: false,
            back: false,
            dateTime: false,
            uuid: window.parent.BotPenguinData.uuid,
            lastQuestion: '',
            profile: window.parent.BotPenguinData.profile || '',
            plan: window.parent.BotPenguinData.plan || '',
            liveChat: false,
            isLanding: window.parent.BotPenguinData.isLanding,
            whitelabel: window.parent.BotPenguinData.whitelabel || {},
            chatRequestAccepted: false,
            chatRequestRejected: false,
            _chatWindowUser: window.parent.BotPenguinData._chatWindowUser,
            agentId: undefined,
            socketDisconnected: false,
            installations: window.parent.BotPenguinData.installations || [],
            agencyData: window.parent.BotPenguinData.agency || {},
            chatMenu: window.parent.BotPenguinData.configuration.chatMenu
          }));
          this.loadData();
        })
        .catch((e) => {

          console.log(
            `%c${e} (https://www.botpenguin.com)`,
            `color: red; font-weight: bold; font-size: ${'20px'
            };`
          );
        });

    } else {
      this.loadData();
    }

  }

  loadData() {

    this.paginateMessageListener();

    // Logic to auto scroll when launcher icon change display block from none
    let frame = window.parent.document.getElementById('BotPenguin-messenger');
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        scroll()
      });
    });
    if (frame) {
      observer.observe(frame, { attributes: true, attributeFilter: ['style'] });
    }

    localStorage.setItem('questions', JSON.stringify([]));
    window.addEventListener('message', (event) => {
      if (event.data.origin === 'BotPenguin') {
        if (event.data.action === 'start') {
          if (!this.props.messages.length) {
            if (!this.state.start) {
              this.setState({
                start: true
              });

              import('../utilities/question')
                .then(data => data.renderQuestion());
              updateMetaToSocket();

              import('../utilities/save-message')
                .then(data => data.addInstallation());
            }
          }
        }
      }
    });

    import('../actions/flow.action')
      .then(({ loadFlow }) =>
        STORE.dispatch(loadFlow())
      );

    if (this.props.environment.isLanding) {
      this.setState({
        start: true
      });

      import('../utilities/question')
        .then(data => data.renderQuestion());
    }

    this.setState({
      theme: {
        ...this.state.theme,
        palette: {
          ...this.state.theme.palette,
          primary: {
            main: this.props.design.themeColor
          },
          text: {
            ...this.state.theme.palette.text,
            primary: this.props.design.textColor
          }
        }
      }
    });

    const root = document.documentElement;
    root.style.setProperty('--primary-theme-color', this.props.design.themeColor);
    getIpDetails()
      .then((result) => {
        this.props.updateMeta({
          ...result,
          ...getDeviceDetails(),
          location: result.city
        });
        if (!this.props.environment.preview) {
          makeSocketConnection({
            ...result,
            ...getDeviceDetails(),
            location: result.city,
            user: this.props.environment._user,
            uuid: this.props.environment.uuid
          });
        }

        import('../utilities/save-message')
          .then(data => {
            data.updateUUID({
              ...result,
              ...getDeviceDetails(),
              location: result.city,
              url: window.location.href
            })
              .then()
              .catch()
          });

        window.parent.postMessage({
          origin: 'BotPenguin',
          action: 'rendered'
        }, '*');
      });


    const state = STORE.getState();
    if (state.environment.liveChat) {

      import('../actions/text.action')
        .then(data => STORE.dispatch(data.updateTextInput({ status: true })));
    }

    this.setState({ showElement: true })
  }

  paginateMessageListener() {
    const timer = setTimeout(() => {
      var elem = document.getElementById('chatArea');
      elem.addEventListener("scroll", () => {

        if (elem.scrollTop === 0) {          
          this.setState({ showLoader: true });
          this.getMessages();
        }

      });
    }, 1);
  }

  getMessages() {
    const storeState = STORE.getState();
    const network = new Network();
    network.post('messages/window', {
      a: encryptPayload({
        uuid: storeState.environment.uuid,
        _user: storeState.environment._user,
        _bot: storeState.environment._id,
        secret: 'BP2031&*())8',
        page: this.state.page,
        skip: storeState.messages.length || 0
      })
    })
      .then((response) => {

        this.setState({ page: this.state.page + 1 });
        STORE.dispatch(oldMessages(response.data));
        this.setState({ showLoader: false, conversationStartedMessage: true });
      })
      .catch(() => {
        this.setState({ showLoader: false, conversationStartedMessage: true });
      })
  }

  render() {
    if (!this.state.showElement) {
      return <div className='loader'>
        <img src={loaderGif}></img>
      </div>
    }

    const { classes } = this.props;
    const isWhitelabeled = this.props.environment.whitelabel?.active || this.props.environment.whitelabel?.byAgency;

    return (
      <div style={{ height: '100dvh' }}>
        {
          this.state.showElement &&
          <div style={{ overflow: 'hidden !important' }}>
            <ThemeProvider theme={this.state.theme}>
              <div ref={window.chatAreaRef} id="chatArea" className={classes.chatArea} style={{
                background: this.props.design.backgroundColor,
                overflow: 'auto',
                marginTop: '0',
                height: 'calc(100dvh - 80px)'
              }}>
                {
                  this.state.showLoader &&
                  <div className='loader-pagination'>
                    <img src={loaderGif}></img>
                  </div>
                }
                {(this.props.messages || []).map((message, index) => renderComponent(message, index))
                }
                {this.props.environment.typing ? <Typing avatar={this.props.design.avatar} /> : null}
                <SkipBack />
              </div>
              <TextField />
            </ThemeProvider>
          </div>

        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  design: state.design,
  messages: state.messages,
  environment: state.environment
});

export default connect(mapStateToProps,
  {
    updateMeta: updateMeta
  })
  (withStyles(styles)(Index));
