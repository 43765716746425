import { STORE } from '../store';
import { updateTextInput } from '../actions/text.action';
import { updateEnvironment } from '../actions/environment.action';
import { newMessage, updateMessage } from '../actions/message.action';
import { focus, scroll, sleep } from './shared';
import { renderQuestion } from './question';
import { saveResponse, updateUUID } from './save-message';
import { emit } from './socket';
import { Network } from './network';
import validator  from 'validator';

const validateQuestions = ['EMAIL', 'PHONE', 'NUMBER', 'NAME', 'APPOINTMENT', 'SMART_QUESTION'];
const excludeAnswers = ['SKIP', 'BACK'];

async function submitAnswer(answer, original = '', next = null) {
  const state = STORE.getState();
  let activeQuestion = state.environment.activeQuestion;
  if (!answer) {
    return;
  }
  if (localStorage.getItem('agentId')) {
    activeQuestion = null
  }
  emit('message', {
    text: answer,
    messageBy: 'user'
  });
  STORE.dispatch(newMessage({
    label: answer,
    position: 'right',
    type: 'STATEMENT',
    questionId: activeQuestion?.id,
    isValid: (activeQuestion?.type === 'name') ||
      (activeQuestion?.type === 'email' && validator.isEmail(answer.trim())) ||
      (activeQuestion?.type === 'phone' && validator.isMobilePhone(answer.trim()))
  }));
  saveResponse({
    type: 'message',
    text: answer,
    messagedBy: 'user',
    questionId: activeQuestion?.id,
    attributeId: activeQuestion?._attribute || ''
  }).then().catch();
  scroll();


  STORE.dispatch(updateMessage({}));
  STORE.dispatch(updateTextInput({
    status: state.environment.liveChat || state.environment.enableTextInput || state.text.status,
    value: ''
  }));

  if (!activeQuestion) {
    return;
  }

  if (validateQuestions.includes(activeQuestion.type.toUpperCase()) && !excludeAnswers.includes(answer.toUpperCase())) {
    STORE.dispatch(updateTextInput({
      value: ''
    }));
    await sleep(0.5);
    switch (activeQuestion.type.toUpperCase()) {
      case 'EMAIL': {
        /**
         * Logic for Whether to Include or Exclude the domains in selecteddomainsList[] 
         */
        const questionData = activeQuestion.message.configureDomains;
        let query;
        (questionData?.domainAction === 'include' && questionData?.selectedDomainsList?.length)
          ? query = (!(questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()))
          : query = ((questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()));

        if (query && checkEmail(answer.trim())) {
          STORE.dispatch(newMessage({
            label: questionData.domainErrorMessage || 'This domain is not acceptable',
            position: 'left',
            type: 'STATEMENT'
          }));
          emit('message', {
            text: questionData.domainErrorMessage || 'This domain is not acceptable',
            messageBy: 'bot'
          });
          saveResponse({
            type: 'message',
            text: questionData.domainErrorMessage || 'This domain is not acceptable',
            messagedBy: 'bot'
          }).then().catch();
          scroll();
          focus();
          return;
        }
        if (checkEmail(answer.trim())) {
          updateUUID({
            email: answer,
            lead: true
          }).then().catch();
          STORE.dispatch(updateEnvironment({
            response: {
              ...state.environment.response,
              email: answer
            }
          }));
          sessionStorage.setItem('attendeeEmail', answer);
          break;
        }

        /**
         * Author:Aman
         */

        STORE.dispatch(newMessage({
          label: activeQuestion.message.error,
          position: 'left',
          type: 'STATEMENT'
        }));
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        // STORE.dispatch( updateTextInput( {
        //   status: true,
        //   value: ''
        // } ) );
        saveResponse({
          type: 'message',
          text: activeQuestion.message.error,
          messagedBy: 'bot'
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'PHONE': {
        if (checkMobileNumber(answer.trim())) {
          updateUUID({
            phone: answer,
            lead: true
          }).then().catch();
          STORE.dispatch(updateEnvironment({
            response: {
              ...state.environment.response,
              phone: answer
            }
          }));
          break;
        }
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        STORE.dispatch(newMessage({
          label: activeQuestion.message.error,
          position: 'left',
          type: 'STATEMENT'
        }));
        saveResponse({
          type: 'message',
          text: activeQuestion.message.error,
          messagedBy: 'bot'
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'NUMBER': {
        if (checkNumeric(answer.trim())) {
          break;
        }
        emit('message', {
          text: activeQuestion.message.error,
          messageBy: 'bot'
        });
        STORE.dispatch(newMessage({
          label: 'Answer must be numeric!',
          position: 'left',
          type: 'STATEMENT'
        }));
        saveResponse({
          type: 'message',
          text: 'Answer must be numeric!',
          messagedBy: 'bot'
        }).then().catch();
        scroll();
        focus();
        return;
      }
      case 'NAME': {
        if (answer.trim().length >= activeQuestion.maxRange) {
          emit('message', {
            text: activeQuestion.message.error,
            messageBy: 'bot'
          });
          STORE.dispatch(newMessage({
            label: activeQuestion.message.error,
            position: 'left',
            type: 'STATEMENT'
          }));
          saveResponse({
            type: 'message',
            text: activeQuestion.message.error,
            messagedBy: 'bot'
          }).then().catch();
          STORE.dispatch(updateTextInput({
            status: true,
            value: ''
          }));
          scroll();
          focus();
          return;
        }
        updateUUID({
          name: answer
        }).then().catch();
        STORE.dispatch(updateEnvironment({
          response: {
            ...state.environment.response,
            name: answer
          }
        }));
        sessionStorage.setItem('name', answer);
        break;
      }
      case 'APPOINTMENT': {
        if (!state.environment.preview && sessionStorage.getItem('attendeeEmail') && checkEmail(sessionStorage.getItem('attendeeEmail'))) {
          const network = new Network();
          await network.post('process-appointment', {
            email: sessionStorage.getItem('attendeeEmail'),
            _user: state.environment._user,
            _bot: state.environment._id,
            start: new Date(answer).toISOString(),
            end: new Date(new Date(answer).setMinutes(new Date(answer).getMinutes() + activeQuestion.appointment.interval)).toISOString()
          });
        }
        break;
      }
      case 'SMART_QUESTION': {
        let found = false;
        let options = activeQuestion.options;
        options = options.sort((a, b) => (a.keywordType.toLowerCase() === 'contain') ? 1 : -1);
        let index;
        options.map(option => {
          if (option.keywordType.toLowerCase() === 'exact' && option.smartKeywords.includes(answer.toLowerCase())) {
            found = true;
            index = state.flows[0].questions.findIndex(q => q.id === option.next.target);
            renderQuestion(index);
            scroll();
            focus();
            return;
          } else if (option.keywordType.toLowerCase() === 'contain') {
            option.smartKeywords.map(keyword => {
              if (answer.toLowerCase().includes(keyword) && !found) {
                found = true;
                index = state.flows[0].questions.findIndex(q => q.id === option.next.target);
                renderQuestion(index);
                return;
              }
            })
          }
        })
        if (found) {
          return;
        }
        break;
      }
      default: {
        scroll();
        focus();
        return;
      }
    }
  }
  STORE.dispatch(updateEnvironment({
    refresh: state.environment.liveChat,
    lastQuestion: activeQuestion.id,
    skip: false,
    back: false
  }));


  if (activeQuestion.includeInLeads) {
    updateUUID({
      lead: true,
      customParameters: {
        [activeQuestion.label]: answer
      }
    }).then().catch();
  }


  scroll();
  focus();
  let index;
  if (next) {
    index = state.flows[0].questions.findIndex(q => q.id === next);
    if (index === -1) {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion.id);
      index = index + 1;
    }
  } else {
    if (activeQuestion.next.target) {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion.next.target);
      if (index === -1) {
        index = state.flows[0].questions.findIndex(q => q.id === activeQuestion.id);
        index = index + 1;
      }
    } else {
      index = state.flows[0].questions.findIndex(q => q.id === activeQuestion.id);
      index = index + 1;
    }
  }
  const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];
  if (!constants.includes(activeQuestion.label.toUpperCase())) {
    if (!localStorage.getItem('questions')) {
      localStorage.setItem('questions', JSON.stringify([]));
    }
    if (localStorage.getItem('questions')) {
      const qs = JSON.parse(localStorage.getItem('questions'));
      localStorage.setItem('questions', JSON.stringify([...qs, {
        question: activeQuestion.label,
        questionId: activeQuestion?.id,
        answer
      }]));
      emit('update-user-details', {
        messages: [...qs, {
          question: activeQuestion.label,
          questionId: activeQuestion?.id,
          answer
        }]
      });
    }
  }
  if (!state.environment.liveChat) {
    renderQuestion(index);
  }
}

const checkEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return email.match(emailRegex);
}

const checkMobileNumber = (number) => {
  const regex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;

  return number.match(regex);
}

const checkNumeric = (number) => {
  const regex = /^[0-9]*$/;

  return number.match(regex);
}

export { submitAnswer };

