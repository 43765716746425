import { LOAD_DESIGN, LOAD_FLOW } from './actions';

const originalFlows = undefined;

let flows = sessionStorage.getItem(`BotPenguin-${window.parent.BotPenguinData?._id}`);

if (flows) {
  try {
    flows = JSON.parse(flows).flows || originalFlows;
  } catch (e) {
    flows = originalFlows;
  }
}

export const loadFlow = () => ({
  type: LOAD_FLOW,
  payload: flows || window.parent.BotPenguinData?.flows || []
});

export const loadDesign = () => ({
  type: LOAD_DESIGN,
  payload: {
    ...window.parent.BotPenguinData.design,
    name: window.parent.BotPenguinData.name
  }
});

export const loadConfig = () => ({
  type: 'LOAD_CONFIG',
  payload: window.parent.BotPenguinData.configuration
});