import { LOAD_DESIGN } from "../actions/actions";

const initialState = window.parent.BotPenguinData || {};

const designReducer = (state = initialState, action) => {
  switch (action.type) {

    case LOAD_DESIGN:
      return action.payload || {
        ...window.parent.BotPenguinData.design,
        name: window.parent.BotPenguinData.name
      }
    default:
      return state;
  }
};

export { designReducer };
