import { messageReducer } from './message.reducer';
import { combineReducers } from 'redux';
import { designReducer } from './design.reducer';
import { flowReducer } from './flow.reducer';
import { configurationReducer } from './configuration.reducer';
import { environmentReducer } from './environment.reducer';
import { agentReducer } from './agent.reducer';
import { textInputReducer } from './text.reducer';
import { metaReducer } from './meta.reducer';

const reducer = combineReducers( {
  design: designReducer,
  flows: flowReducer,
  configuration: configurationReducer,
  environment: environmentReducer,
  messages: messageReducer,
  agents: agentReducer,
  text: textInputReducer,
  meta: metaReducer
} );

export { reducer };
