import UAParser from 'ua-parser-js';

function getTime(num) {
  const tempHour = String(Math.trunc(num / 60));
  const hour = parseInt(tempHour + ''.length === 1 ? '0' + tempHour : tempHour);
  const min = num % 60 === 0 ? '00' : num % 60;
  return {
    num: num,
    time: (hour > 12 ? (hour === 24 ? 0 : hour - 12) : hour) + ':' + min + `${hour >= 12 ? ' PM' : ' AM'}`
  };
}

function getTimeSlots(blockTimes, showTimeAsString, interval, includeStartBlockedTime, includeEndBlockedTime) {
  let times,
    sums = 60;
  includeStartBlockedTime = includeStartBlockedTime === true;
  includeEndBlockedTime = includeEndBlockedTime === true;
  switch (interval) {
    case 'tenth':
      times = 6;
      sums = 10;
      break;
    case 'quarter':
      times = 4;
      sums = 15;
      break;
    case 'half':
      times = 2;
      sums = 30;
      break;
    case 'one':
      times = 1;
      sums = 60;
      break;
    case 'two':
      times = 1 / 2;
      sums = 120;
      break;
    case 'three':
      times = 1 / 3;
      sums = 180;
      break;
    case 'four':
      times = 1 / 4;
      sums = 240;
      break;
    default:
      times = 1;
      sums = 60;
      break;
  }
  let start = 0;
  let dateTimes = Array(Math.round(24 * times))
    .fill(0)
    .map(function (_) {
      start = start + sums;
      return start;
    });
  blockTimes = Array.isArray(blockTimes) === true && blockTimes.length > 0 ? blockTimes : [];
  if (blockTimes.length > 0) {
    dateTimes = blockTimes.reduce(function (acc, x) {
      return acc
        .filter(function (y) {
          return includeStartBlockedTime === true ? y <= x[0] : y < x[0];
        })
        .concat(
          acc.filter(function (y) {
            // eslint-disable-next-line eqeqeq
            return includeEndBlockedTime === true ? y >= x[1] : y > x[1];
          })
        );
    }, dateTimes);
  }
  if (showTimeAsString === true) {
    return dateTimes
      .map(function (x) {
        return getTime(x);
      })
      .reduce(function (accc, element) {
        accc['' + element.num] = element.time;
        return accc;
      }, {});
  }
  return dateTimes;
}


async function sleep(s) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, s * 1000);
  });
}

const scroll = (time = 300) => {
  setTimeout(() => {
    try {
      let scrollHeight = window.chatAreaRef.current.scrollHeight;
      //document.querySelector('#chatArea').scrollTop = scrollHeight;
      /**
       * Commented for optimization purpose
       * Author: Aman
       * Date: Feb 25, 2022
       */

      for (let i = 0; i < scrollHeight; i++) {
        document.querySelector('#chatArea').scrollTop = i;
      }
    } catch (e) {
    }
  }, time);
};

function focus() {
  if (window.inputRef && window.inputRef.current && window.inputRef.current.focus)
    window.inputRef.current.focus();
}

function getDeviceDetails() {
  const parser = new UAParser();
  const ua = parser.getResult();
  return {
    operatingSystem: ua.os.name + ' ' + ua.os.version,
    device: (ua.device.type || 'Desktop') + `(${(ua.device.vendor || '') + ' ' + (ua.device.model || '')})`,
    browser: ua.browser.name + ' ' + ua.browser.version
  };
}

async function getIpDetails() {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  let metaLocation = localStorage.getItem('BotPenguin-location');
  try {
    if (metaLocation) {
      metaLocation = JSON.parse(metaLocation);
      return new Promise(resolve => {
        return resolve(metaLocation);
      });
    }
  } catch (e) {
    console.log(e.message);
  }
  return new Promise((resolve, reject) => {
    fetch('https://api.botpenguin.com/meta/ip', requestOptions)
      .then(response => response.json())
      .then((response) => {
        resolve(response);
        localStorage.setItem('BotPenguin-location', JSON.stringify(response));
      })
      .catch(reject);
  });
}


export { getTimeSlots, sleep, scroll, focus, getDeviceDetails, getIpDetails };
