import { UPDATE_TEXT_INPUT } from '../actions/actions';

const textInputReducer = (state = {
  status: false,
  value: '',
  original: ''
}, action) => {
  switch (action.type) {
    case UPDATE_TEXT_INPUT: {
      return {
        ...state,
        ...action.payload,
        original: action.payload.original || action.payload.value || ''
      };
    }
    case "LOAD_INITIAL_TEXT": {

      let text = window.parent.BotPenguinData.configuration?.generalSettings?.userChatRetention === 'forever'
        ? localStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`)
        : sessionStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`);

      if (text) {
        try {
          text = JSON.parse(text).text || {
            status: false,
            value: '',
            original: ''
          };
        } catch (e) {
          text = {
            status: false,
            value: '',
            original: ''
          };
        }
      }

      return text;
    }
    default: {
      return state;
    }
  }
};

export { textInputReducer };
