import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { Typography } from '@material-ui/core';
import smile from '../../assets/icons/smile.png';
import { updateTextInput } from '../../actions/text.action';
import { submitAnswer } from '../../utilities/answer';
import { advanceMessage } from '../../utilities/socket';
import { STORE } from '../../store';

const styles = theme => {
  return ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: 'white',
      borderTop: `1px solid  ${theme.palette.primary.main}`
    },
    powered: {
      padding: 3,
      // margin: 'auto',
      width: '100%',
      // left: 0,
      right: 0,
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: 12
    },
    icon: {
      verticalAlign: 'middle',
      fill: '#FFC107',
      fontSize: '1.2rem'

    },
    input: {
      border: 'none !important',
      fontWeight: 600,
      color: '#ccc'
    }
  });
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      emoji: false,
    };
    this.timeout = null;
    window.inputRef = React.createRef();

  };

  whitelabel = STORE.getState();

  componentDidMount() {
    // scroll();
  }

  toggleEmoji = () => {
    this.setState(prev => {
      return {
        emoji: !prev.emoji
      };
    });
  };

  chooseEmoji = (event) => {
    this.props.updateTextInput({
      value: this.props.textInput.value + event.native,
    });
  };

  focus = () => {
    // scroll( 100 );
  };

  handleChange = (event) => {
    this.setState({
      text: event.target.value
    });
    this.props.updateTextInput({
      value: event.target.value
    });
    // userTyping(this.props.environment.uuid, "start")
    if (localStorage.getItem('agentId')) {
      advanceMessage({ uuid: this.props.environment.uuid, advanceMessage: event.target.value })
    }
  };

  keyDown = (event) => {
    if (event.key === 'Enter') {
      this.send();
    }
    this.setState({
      emoji: false
    });
  };


  send = () => {
    if (this.props.textInput.value.trim() !== '') {
      submitAnswer(this.props.textInput.value, this.props.textInput.original);
      this.setState({
        emoji: false
      });
    }
  };

  render() {
    const isWhitelabeled = this.props.environment.whitelabel?.active || this.props.environment.whitelabel?.byAgency;
    if (isWhitelabeled) {
      return (<></>);
    }

    return this.displayBasic();
  }


  displayBasic() {
    const { classes } = this.props;
    return (
      <div className={classes.root}
        style={{ height: '80px' }}>
        <Input
          inputRef={window.inputRef}
          onClick={this.focus}
          disabled={!this.props.textInput.status}
          // disabled={false}
          className={classes.input}
          fullWidth
          id="inputMessage"
          variant="outlined"
          placeholder={this.props.textInput.status ? (this.props.environment?.chatMenu.placeholder || 'Type your answer') : ''}
          type="text"
          value={this.props.textInput.value}
          onKeyUp={(e) => this.keyDown(e)}
          onChange={(e) => this.handleChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">            
                <IconButton
                  disabled={false}
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={this.send}
                  color="primary"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Paper
          elevation={0}
          component={'div'}
          className={classes.powered}
          style={{}}
        >
          <Typography component={'p'}
            style={{
              fontSize: 14,
              color: '#1661f5',
              display: 'block'
            }}>
            Chat <FlashOnIcon className={classes.icon} /> by <a
              href={
                this.props.environment.agencyData?.type === 'WHITE_LABEL'
                  ? this.props.environment.agencyData.meta.websiteUrl
                  : 'https://botpenguin.com/?utm_source=referral&utm_medium=' + window.location.host + '&utm_campaign=chat_window'}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: '#1661f5'
              }}>{this.props?.environment?.agencyData?.meta?.brand || 'BotPenguin'}</a>
          </Typography>
          <p></p>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  textInput: state.text,
  environment: state.environment
});

export default connect(mapStateToProps, {
  updateTextInput
})(withStyles(styles)(TextField));
