import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles( {
  avatar: {
    margin: 10,

  },
  small: {
    margin: 10,
    height: 25,
    width: 25,
    border: '1px solid var(--primary-theme-color)'
  }
} );

export default function ImageAvatars( props ) {
  const classes = useStyles();

  return (
    <Avatar
      alt="Avatar"
      src={ props.avatar }
      className={ props.small ? classes.small : classes.avatar } style={ props.small ? { marginTop: 15 } : null }/>
  );
}
