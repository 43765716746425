import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import Bubble from '../Bubble';
import { updateEnvironment } from '../../actions/environment.action';
import { updateTextInput } from '../../actions/text.action';
import { getTimeSlots } from '../../utilities';

const styles = theme => ( {
  dateTime: {
    width: '100%',
    height: 'height: calc(100% - 151px);',
    border: 'none',
    position: 'fixed',
    top: 65,
    bottom: 85,
    background: '#ffffff',
    overflowY: 'auto'
  },
  slots: {
    margin: 10,
  },
  slot: {
    margin: 5,
    borderRadius: 50
  },
  time: {
    display: 'flex',
    minHeight: '70%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    fontSize: '20px'
  },
  hour: {
    border: '1px solid #ccc',
    padding: '15px',
    borderRadius: '10px',
    display: 'grid',
    textAlign: 'center'
  },
  ampm: {
    border: '1px solid #ccc',
    padding: '15px',
    borderRadius: '10px',
    display: 'grid',
    textAlign: 'center'
  },
  minute: {
    border: '1px solid #ccc',
    padding: '15px',
    borderRadius: '10px',
    display: 'grid',
    textAlign: 'center'
  }
} );

class DateTime extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      calender: false,
      time: false,
      slots: false,
      selectedTimeValue: '',
      selectedTimeOriginal: '',
      currentHour: ( new Date().getHours() + 24 ) % 12 || 12,
      currentMinute: new Date().getMinutes(),
      script: new Date().getHours() >= 12 ? 'PM' : 'AM',
      dateString: ''
    };
  }

  chooseDate = ( event ) => {
    this.setState( {
      calender: false,
      time: !!this.props.time,
      slots: !!this.props.slots,
      selectedTimeOriginal: +new Date( event ),
      selectedTimeValue: new Date( event ).toLocaleDateString( this.props.configuration.generalSettings.language )
    } );
    if ( !this.props.time ) {
      this.props.updateEnvironment( {
        dateTime: false
      } );
    }
    if ( !this.props.slots ) {
      this.props.updateEnvironment( {
        dateTime: false
      } );
    }
    this.setState( {
      dateString: new Date( event ).toDateString()
    } );
    this.props.updateTextInput( {
      value: String( new Date( event ).toDateString() + ( this.props.time ? String(
        ' ' + this.state.currentHour + ':' +
        this.state.currentMinute + ' ' +
        this.state.script
      ) : '' ) ),
      original: !this.props.time ? +new Date( event ) : String( new Date( event ).toDateString() + ( this.props.time ? String(
        ' ' + this.state.currentHour + ':' +
        this.state.currentMinute + ' ' +
        this.state.script
      ) : '' ) )
    } );
  };

  chooseSlot = ( slot ) => {
    this.setState( {
      calender: false,
      time: false,
      slots: false
    } );
    this.props.updateEnvironment( {
      dateTime: false
    } );

    let originalTime = new Date( this.state.selectedTimeOriginal );

    this.props.updateTextInput( {
      value: originalTime.toDateString() + ' ' + slot,
      original: originalTime.toDateString() + ' ' + slot
    } );
  };

  openCalender = () => {
    this.props.updateEnvironment( {
      dateTime: true
    } );
    this.setState( {
      calender: true
    } );
  };

  onRender = ( { activeStartDate, date, view } ) => {
    const availableDays = this.props.availableDays.length ? this.props.availableDays : [ 0, 1, 2, 3, 4, 5, 6 ];
    return !availableDays.includes( date.getDay() );
  };

  getStartEndTime = () => {
    let start = this.props.startTime;
    let end = this.props.endTime;

    if ( start.match( /AM/ ) ) {
      start = parseInt( start.split( ':' )[ 0 ] );
    } else {
      start = parseInt( start.split( ':' )[ 0 ] );
      if ( start === 12 ) {
        start = 0;
      } else {
        start = start + 12;
      }
    }

    if ( end.match( /AM/ ) ) {
      end = parseInt( end.split( ':' )[ 0 ] );
    } else {
      end = parseInt( end.split( ':' )[ 0 ] );
      if ( end === 12 ) {
        end = 0;
      } else {
        end = end + 12;
      }
    }
    return { start: start * 60, end: end * 60 };
  };


  setTime = () => {
    this.props.updateTextInput( {
      value: String( this.state.dateString + ( this.props.time ? String(
        ' ' + this.state.currentHour + ':' +
        this.state.currentMinute + ' ' +
        this.state.script
      ) : '' ) ),
      original: String( this.state.dateString + ( this.props.time ? String(
        ' ' + this.state.currentHour + ':' +
        this.state.currentMinute + ' ' +
        this.state.script
      ) : '' ) )
    } );
  };

  handleChangeTime = ( type, value ) => {
    switch ( type ) {
      case 'hour': {
        if ( value > 12 ) {
          this.setState( {
            currentHour: 1
          } );
        } else if ( value < 1 ) {
          this.setState( {
            currentHour: 12
          } );
        } else {
          this.setState( {
            currentHour: value
          } );
        }
        break;
      }
      case 'minute': {
        if ( value > 59 ) {
          this.setState( {
            currentMinute: 0
          } );
        } else if ( value < 0 ) {
          this.setState( {
            currentMinute: 59
          } );
        } else {
          this.setState( {
            currentMinute: value
          } );
        }
        break;
      }
      case 'script': {
        this.setState( {
          script: value
        } );
        break;
      }
      default: {

      }
    }
    setTimeout( () => {
      this.setTime();
    }, 300 );
  };

  render() {
    const { classes } = this.props;
    let end, start, slots;
    if ( this.props.slots ) {
      end = this.getStartEndTime().end;
      start = this.getStartEndTime().start;
      let greaterCondition = false;
      if ( start > end ) {
        greaterCondition = true;
        let temp = start;
        start = end;
        end = temp;
      }
      slots = Object.values( getTimeSlots( [ [ 0, start ], [ end, 5000 ] ], true, this.props.slotInteval ) );
      if ( greaterCondition ) {
        slots.reverse();
      }
    }
    return (
      <>
        <Bubble text={ this.props.text } avatar={ this.props.avatar }/>
        <Button onClick={ this.openCalender } style={
          {
            marginLeft: 50,
            textTransform: 'none',
            fontWeight: 'bolder',
            borderRadius: 30,
            color: this.props.design.textColor
          }
        } variant={ 'contained' } color={ 'primary' }
        >Open Calender
        </Button>
        <Slide direction={ 'up' } in={ this.state.calender } timeout={ 500 }>
          <Calendar tileDisabled={ this.onRender } minDate={ this.props.min } maxDate={ this.props.max }
                    onClickDay={ this.chooseDate }
                    className={ classes.dateTime }
                    locale={ this.props.configuration.generalSettings.language }
          />

        </Slide>
        { this.props.time ? <Slide direction={ 'up' } in={ this.state.time } timeout={ 500 }>
          <div className={ classes.dateTime }>
            <h2 style={ { textAlign: 'center' } }>Select time</h2>
            <div className={ classes.time }>
              <div className={ classes.hour }>
                <span onClick={ () => this.handleChangeTime( 'hour', this.state.currentHour + 1 ) }
                      className="material-icons">
                  keyboard_arrow_up
                </span>
                <span>
                  { this.state.currentHour }
                </span>
                <span onClick={ () => this.handleChangeTime( 'hour', this.state.currentHour - 1 ) }
                      className="material-icons">
                  keyboard_arrow_down
                </span>
              </div>
              <div className={ classes.minute }>
                <span onClick={ () => this.handleChangeTime( 'minute', this.state.currentMinute + 1 ) }
                      className="material-icons">
                  keyboard_arrow_up
                </span>
                <span>
                  { this.state.currentMinute }
                </span>
                <span onClick={ () => this.handleChangeTime( 'minute', this.state.currentMinute - 1 ) }
                      className="material-icons">
                  keyboard_arrow_down
                </span>
              </div>
              <div className={ classes.ampm }>
                <span onClick={ () => this.handleChangeTime( 'script', this.state.script === 'AM' ? 'PM' : 'AM' ) }
                      className="material-icons">
                  keyboard_arrow_up
                </span>
                <span>
                  { this.state.script }
                </span>
                <span onClick={ () => this.handleChangeTime( 'script', this.state.script === 'AM' ? 'PM' : 'AM' ) }
                      className="material-icons">
                  keyboard_arrow_down
                </span>
              </div>
            </div>

          </div>
        </Slide> : null }
        { this.props.slots ? <Slide direction={ 'up' } in={ this.state.slots } timeout={ 500 }>
          <div className={ classes.dateTime } style={ { textAlign: 'center' } }>
            <h4 style={ { margin: 10 } }>Choose a time slot</h4>
            { slots.map( ( slot, index ) =>
              <Button
                onClick={ () => this.chooseSlot( slot ) }
                className={ classes.slot }
                key={ index }
                variant={ 'outlined' }
                color={ 'primary' }
              >{ slot }</Button> ) }
          </div>
        </Slide> : null }
      </>
    );
  }

}


const mapStateToProps = state => ( {
  configuration: state.configuration,
  design: state.design
} );

export default connect( mapStateToProps, {
  updateEnvironment,
  updateTextInput
} )( withStyles( styles )( DateTime ) );
