import { Network } from './network';
import { STORE } from '../store';
import CryptoJS from 'crypto-js';
const network = new Network();

const encryptPayload = (payload) => {
  const newPayload = JSON.stringify(payload);

  return CryptoJS
    .AES
    .encrypt(newPayload, 'user-payload')
    .toString();
};
const saveResponse = async (payload) => new Promise(async (resolve) => {
  const { environment } = STORE.getState();
  resolve();
  if (!environment.preview) {
    if (!localStorage.getItem('__BotPenguinUser')) {
      localStorage.setItem('__BotPenguinUser', environment._user);
      localStorage.setItem('__BotPenguin', environment._id);
    }
    await network.post('save-response', {
      binary: encryptPayload({
        payload,
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile,
        isLiveChat: localStorage.getItem('agentId') ? true : false,
        agentId: localStorage.getItem('agentId') || environment._user,
      })
    });
  }
});


const updateUUID = async (payload) => new Promise(async (resolve) => {
  const { environment, meta } = STORE.getState();
  resolve();
  if (!environment.preview) {
    await network.put('update-meta', {
      binary: encryptPayload({
        payload: {
          ...meta,
          ...payload
        },
        _bot: environment._id,
        _user: environment._user,
        uuid: environment.uuid,
        profile: environment.profile
      })
    });
  }
});

const addInstallation = async () => new Promise(async (resolve) => {
  new Promise(async (resolve) => {
    const { environment } = STORE.getState();
    resolve();
    if (!environment.preview) {
      const installations = environment.installations || [];
      var baseUrl = window.location.origin;
      if (!installations.find(data => data.siteUrl === baseUrl)) {
        const network = new Network();
        await network.post('bot-installation', {
          binary: encryptPayload({
            botId: localStorage.getItem('__BotPenguin'),
            _user: localStorage.getItem('__BotPenguinUser'),
            siteUrl: baseUrl
          })
        });
      }
    }
  });
});



export { saveResponse, updateUUID, addInstallation, encryptPayload };
