import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Bubble from '../Bubble';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { submitAnswer } from '../../utilities/answer';
import { Network } from '../../utilities/network';


const styles = theme => ( {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing( 1 ),
  },
  loader: {
    margin: 10
  }
} );


class File extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      showLoader: false
    };
  }

  upload = ( e ) => {
    this.setState( {
      showLoader: true
    } );
    const network = new Network();

    const formData = new FormData();
    formData.append( 'file', e.target.files[ 0 ], e.target.files[ 0 ].name );

    network.upload( formData )
      .then( ( { data } ) => {
        this.setState( {
          showLoader: false
        } );
        submitAnswer( data )
          .then();
      } );

  };

  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={ this.props.avatar } text={ text || 'Upload a file' }/>
        <div style={ { marginLeft: 40 } } className={ classes.container }>
          { this.state.showLoader ? <CircularProgress value={ 50 } size={ 50 } className={ classes.loader }/> :
            <FormControl className={ classes.formControl } variant="outlined">
              <InputLabel
                ref={ ref => {
                  this.labelRef = ReactDOM.findDOMNode( ref );
                } }
                htmlFor="component-outlined"
              >
              </InputLabel>
              <OutlinedInput
                title={ 'Upload a file' }
                type={ 'file' }
                id="component-outlined"
                value={ this.state.name }
                onChange={ ( e ) => this.upload( e ) }
                labelWidth={ this.labelRef ? this.labelRef.offsetWidth : 0 }
              />
            </FormControl> }

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ( {
  design: state.design
} );

export default connect( mapStateToProps, {} )( withStyles( styles )( File ) );
