import request from 'request';

class Network {
  host;
  //TODO: add live url later
  // constructor(host = 'https://api-dev-botpenguin.relinns.com.au/') {
  //   this.host = host;
  // }

  constructor(host = 'https://api.v7.botpenguin.com/') {
    this.host = host;
  }

  http = async (url, method, body = null) => {
    return new Promise((resolve, reject) => {
      request(this.host + url, {
        method,
        body,
        json: true,
        withCredentials: true
      }, (error, response, body1) => {
        if (error) {
          reject(error);
        } else {
          if (response.statusCode === 200 || response.statusCode === 201 || response.statusCode === 202) {
            resolve(body1);
          } else {
            reject(body1);
          }
        }
      });
    });
  };
  get = async (url) => this.http(url);
  post = async (url, body) => this.http(url, 'POST', body);
  put = async (url, body) => this.http(url, 'PUT', body);

  upload = async (formData) => new Promise((resolve, reject) => {
    fetch(this.host + 'upload', {
      body: formData,
      method: 'POST'
    })
      .then(res => res.json())
      .then(resolve)
      .catch(reject);
  });

}

export { Network };
