import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { submitAnswer } from '../../utilities/answer';

const style = () => ( {
  skipBack: {
    textAlign: 'center'
  },
  button: {
    margin: 5,
    borderRadius: 20
  }
} );

class SkipBack extends React.Component {
  skip = async () => {
    await submitAnswer( 'Skip', 'Skip' );
  };

  back = async () => {
    await submitAnswer( 'Back', 'Back', this.props.environment.lastQuestion );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={ classes.skipBack }>
        { this.props.environment.back ?
          <Button onClick={ this.back } size={ 'small' } color={ 'primary' } className={ classes.button }
                  variant={ 'outlined' }>
            { 'Back' }
          </Button> : null }
        { this.props.environment.skip ?
          <Button onClick={ this.skip } size={ 'small' } color={ 'primary' } className={ classes.button }
                  variant={ 'outlined' }>
            { 'Skip' }
          </Button> : null }
      </div>
    );
  }
}


const mapStateToProps = state => ( {
  environment: state.environment,
} );

export default connect( mapStateToProps, {} )( withStyles( style )( SkipBack ) );
