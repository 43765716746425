export const days = {
  'sun': 0,
  'mon': 1,
  'tue': 2,
  'wed': 3,
  'thu': 4,
  'fri': 5,
  'sat': 6,
};

export const intervals = {
  15: 'quarter',
  10: 'tenth',
  30: 'half',
  60: 'one',
  120: 'two'
};
