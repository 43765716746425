import { STORE } from '../store';
import { newMessage } from '../actions/message.action';
import { updateTextInput } from '../actions/text.action';
import { updateEnvironment } from '../actions/environment.action';
import { focus, scroll, sleep } from './shared';
import { saveResponse } from './save-message';
import { emit } from './socket';
import Mustache from 'mustache';
import { requestLivechat } from './live-chat';

const keyboard = ['QUESTION', 'EMAIL', 'PHONE', 'NUMBER', 'LOCATION', 'NAME', 'LIVE_CHAT', 'SMART_QUESTION'];
const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];


function findQuestions(questions, index) {
  return new Promise(async (resolve) => {
    const state = STORE.getState();
    const messages = state.messages;
    let finalQuestions = [];
    let questionsAlreadyAnswered = (messages || [])
      .filter(message => !!message.questionId && message.isValid)
      .map(message => message.questionId);

    if (!questions[index]) {
      return resolve(finalQuestions);
    }

    if (constants.includes(questions[index].type.toUpperCase())) {
      finalQuestions = [...finalQuestions, questions[index]];
      if (questions[index].next.target) {
        if (questions[index].next.target.match(/end/)) {
          return resolve(finalQuestions);
        }
        const newIndex = questions.findIndex((ques) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }
      } else {
        finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
      }
    } else {
      if (
        questions[index].skipIfAnswered &&
        questionsAlreadyAnswered.includes(questions[index].id) &&
        ['EMAIL', 'PHONE', 'NAME'].includes(questions[index].type.toUpperCase())
      ) {
        const newIndex = questions.findIndex((ques) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }

      } else {
        finalQuestions = [...finalQuestions, questions[index]];
      }
    }
    return resolve(finalQuestions);
  });
}

const renderQuestion = (index = 0) => {
  const state = STORE.getState();
  STORE.dispatch(updateEnvironment({
    typing: false,
    refresh: true,
  }));
  STORE.dispatch(updateTextInput({
    status: false
  }));
  findQuestions(state.flows[0].questions, index).then(async (messages) => {
    if (!messages.length) {
      emit('update-user-details', {
        isCompleted: true
      });
    } else {
      if (messages[messages.length - 1].type.toLowerCase() === 'statement') {
        emit('update-user-details', {
          isCompleted: true
        });
      }
    }
    for await (let message of messages) {
      let messageText = message.label.replace("{{name}}", sessionStorage.getItem('name') || 'User');
      try {
        messageText = Mustache.render(messageText, state.environment.response || {});
      } catch (e) {
        console.error(e.message);
      }
      STORE.dispatch(updateEnvironment({
        typing: true,
        refresh: false,
        skip: false,
        back: false
      }));
      scroll(100);
      await sleep(Math.abs(message.delay));
      saveResponse({
        type: 'message',
        text: messageText,
        messagedBy: 'bot'
      }).then().catch();
      emit('message', {
        text: messageText,
        messageBy: 'bot'
      });
      STORE.dispatch(newMessage({ ...message, label: messageText }));
      STORE.dispatch(updateEnvironment({
        activeQuestion: message,
        typing: false,
        activeQuestionType: message.type,
        refresh: true,
        skip: message.skip,
        back: message.back
      }));
      STORE.dispatch(updateTextInput({
        status: keyboard.includes(message.type.toUpperCase())
      }));
      scroll(300);
      await sleep();
      focus();
      scroll(500);
      focus();
      if (message.type.toUpperCase() === 'LIVE_CHAT') {
        STORE.dispatch(updateEnvironment({
          liveChat: true
        }));

        requestLivechat(state);
      }
    }
  });
};

export { findQuestions, renderQuestion };
