import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
  },
  contentCenter: {
    width: '100%',
    margin: '0 auto'
  },
  headingBlue: {
    color: '#118CFC',
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: '800',
    marginBottom: '0'
  },
  headingOrange: {
    color: '#D16B13',
    fontSize: '16px',
    textTransform: 'capitalize',
    fontWeight: '800',
    marginBottom: '0'
  },
  content: {
    marginTop: '0',
    color: '#707070',
    fontSize: '14px'
  }
});

export default function PushNotification(props) {
  const classes = useStyles();
  return (
    <>
     <>
          <div className={classes.textCenter}>
            <div className={classes.contentCenter}>
              {props.color === 'blue' ? 
              <h4 className={classes.headingBlue}>{props.title} </h4>
              :
              <h4 className={classes.headingOrange}>{props.title} </h4>
              }
              <p className={classes.content}>{props.text}</p>
            </div>
          </div>
        </> 
    </>

  );
}
