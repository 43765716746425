import { LOAD_FLOW } from '../actions/actions';

const flowReducer = ( state = [], action ) => {
  if ( action.type === LOAD_FLOW ) {
    return action.payload;
  }
  return state;
};

export { flowReducer };
