const initialState = window.parent.BotPenguinData?.configuration || {};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_CONFIG':
      return action.payload;
    default:
      return state;
  }
};

export { configurationReducer };
