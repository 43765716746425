import React from 'react';
import Bubble from '../Bubble';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import facebook from '../../assets/icons/facebook.png';
import email from '../../assets/icons/email.png';
import github from '../../assets/icons/github.png';
import instagram from '../../assets/icons/instagram.png';
import link from '../../assets/icons/link.png';
import linkedin from '../../assets/icons/linkedin.png';
import skype from '../../assets/icons/skype.png';
import phone from '../../assets/icons/telephone.png';
import twitter from '../../assets/icons/twitter.png';
import youtube from '../../assets/icons/youtube.png';
import whatsapp from '../../assets/icons/whatsapp.png';
import messenger from '../../assets/icons/messenger.png';
import telegram from '../../assets/icons/telegram.png';
import snapchat from '../../assets/icons/snapchat.png';
import Typography from '@material-ui/core/Typography';


const styles = theme => ( {
  margin: {
    margin: 2,
    borderRadius: 10,
    paddingRight: 15,
    textTransform: 'none',
  },
  icon: {
    // padding: 9,
    width: 20
  }
} );

class Contact extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      buttons: [ 'option 1', 'option 2', 'option 3', 'option 4', 'option 5', 'option 6' ],
      show: true,
      icon: {
        email,
        github,
        instagram,
        link,
        linkedin,
        skype,
        phone,
        twitter,
        youtube,
        whatsapp,
        messenger,
        facebook,
        telegram,
        snapchat
      },
      links: {
        email: 'mailto:',
        github: 'https://github.com/',
        instagram: 'https://www.instagram.com/',
        link: '',
        linkedin: 'https://www.linkedin.com/in/',
        skype: 'https://join.skype.com/invite /',
        phone: 'tel:',
        twitter: 'https://twitter.com/',
        youtube: '',
        whatsapp: 'https://wa.me/',
        messenger: 'https://m.me/',
        facebook: 'https://facebook.com/',
        telegram: 'https://t.me/',
        snapchat: 'https://snapchat.com/add/'
      }
    };
  }

  selectOption = ( link, type ) => {
    window.open( this.state.links[ type ] + link, '_blank' );
  };


  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={ this.props.avatar } text={ text || 'Contact Us' }/>
        <div style={ { marginLeft: 40 } }>
          { this.props.buttons.map( ( button, index ) => (
            <Button onClick={ ( e ) => {
              this.selectOption( button.value, button.type );
            } } key={ index } variant="outlined" color="primary"
                    aria-label="add"
                    className={ classes.margin } size={ 'small' } style={ { color: 'black' } }
                    startIcon={ <img alt={ button.type } className={ classes.icon }
                                     src={ this.state.icon[ button.type ] }/> }>
              <Typography>
                { button.title }
              </Typography>
            </Button> ) ) }
        </div>
      </>
    );
  }
}

export default connect( null, {} )( withStyles( styles )( Contact ) );
