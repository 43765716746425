import { NEW_MESSAGE, UPDATE_MESSAGES, NEW_NOTIFICATION, LIVE_CHAT_NOTIFICATION, OLD_MESSAGES } from '../actions/actions';

const excludes = ['IMAGE', 'VIDEO', 'CONTACT', 'PRODUCT', 'NEW_NOTIFICATION', 'LIVE_CHAT_NOTIFICATION'];

// let localMessages = window.parent.BotPenguinData.configuration?.generalSettings?.userChatRetention === 'forever'
//   ? localStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`)
//   : sessionStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`);
// if (localMessages) {
//   try {
//     localMessages = JSON.parse(localMessages).messages || [];
//   } catch (e) {
//     localMessages = [];
//   }
// }

const messageReducer = (state = [], action) => {
  state = state || [];

  switch (action.type) {
    // Only keeping last 10 messages on frontend. Previous messages are fetched using APIs 
    case NEW_MESSAGE: {
      return [...state, { ...action.payload }];
    }
    case OLD_MESSAGES: {
      const data = action.payload
        .reverse()
        .map(x => ({ ...x, label: x.text, position: x.messagedBy === 'bot' ? 'left' : 'right' }));

      return [...data, ...state];
    }
    case UPDATE_MESSAGES: {
      return [...state.map(message => {
        if (!excludes.includes(message.type.toUpperCase())) {
          message.type = 'STATEMENT';
          message.back = false;
          message.skip = false;
        }
        return message;
      })];
    }
    case NEW_NOTIFICATION: {
      let message = {
        label: action.payload.label,
        type: 'NEW_NOTIFICATION'
      }
      return [...state, message];
    }
    case LIVE_CHAT_NOTIFICATION: {
      let message = {
        ...action.payload,
        type: 'LIVE_CHAT_NOTIFICATION'
      }
      return [...state, message]
    }
    case 'LOAD_INITIAL_MESSAGES': {
      let localMessages = window.parent.BotPenguinData.configuration?.generalSettings?.userChatRetention === 'forever'
        ? localStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`)
        : sessionStorage.getItem(`BotPenguin-${window.parent.BotPenguinData._id}`);

      if (localMessages) {
        try {
          localMessages = JSON.parse(localMessages).messages || [];
        } catch (e) {
          localMessages = [];
        }
      }

      return localMessages;
    }
    default: {
      return state;
    }
  }
};

export { messageReducer };
