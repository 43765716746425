import { createStore } from 'redux';
import { reducer } from '../reducers';

const STORE = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

STORE.subscribe(() => {
  if (window.parent.BotPenguinData && !window.parent.BotPenguinData?.preview) {

    const state = STORE.getState();
    const opitimedState = JSON.stringify({ ...state, messages: (state.messages || []).slice(-20) });

    sessionStorage.setItem(`BotPenguin-${window.parent.BotPenguinData._id}`, opitimedState);
    localStorage.setItem(`BotPenguin-${window.parent.BotPenguinData._id}`, opitimedState);
  }
});

export { STORE };
