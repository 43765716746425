import React from 'react';
import Bubble from '../Bubble';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { newMessage, updateMessage } from '../../actions/message.action';
import { submitAnswer } from '../../utilities/answer';


const styles = () => ( {
  margin: {
    margin: 2,
    borderRadius: 20,
    textTransform: 'none',
    padding: '0px 10px'
  },
  smiles: {
    fontSize: 25
  }
} );

class Rating extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      buttons: [ '&#128545;', '&#128542;', '&#128528;', '&#128522;', '&#128525;' ],
      show: true
    };
  }

  selectOption = ( name ) => {
    // this.props.updateMessage( {} );
    // this.props.newMessage( {
    //   type: 'STATEMENT',
    //   label: name,
    //   position: 'right'
    // } );
    submitAnswer( name, name, null );
    // communicate({
    //   ...this.props.config,
    //   text: name,
    //   event: 'continuous'
    // }).then(async ({response}) => {
    //
    //   }
    // ).catch(console.log);
  };


  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={ this.props.avatar } text={ text || 'Select a button' } theme={ this.props.theme }
                color={ this.props.color }/>
        <div style={ { marginLeft: 40 } }>
          { this.props.buttons.map( ( button, index ) => (
            <Button onClick={ (  ) => {
              this.selectOption( button );
            } } key={ index } variant="outlined" color="primary"
                    aria-label="add"
                    className={ classes.margin } size={ 'medium' }>
              <div className={ classes.smiles } dangerouslySetInnerHTML={ { __html: this.state.buttons[ index ] } }/>
              &nbsp;<Typography>
              { button }
            </Typography>
            </Button> ) ) }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ( {
  config: state.config
} );


export default connect( mapStateToProps, {
  newMessage,
  updateMessage
} )( withStyles( styles )( Rating ) );
