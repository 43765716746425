import React from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import typingGif from '../../assets/gif/typing.gif';
import ImageAvatars from '../Avatar';


const style = theme => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    width: 'fit-content',
    maxWidth: '60%',
    borderRadius: '20px 20px 20px 0px'
  },
  image: {
    height: 20,
    padding: 0,
    margin: 'auto',
    paddingTop: 10
  }
})
  ;

function Typing(props) {
  const { classes } = props;
  return (
    <div style={{ display: 'flex' }}>
      <ImageAvatars avatar={props.avatar} small={true} />
      <Paper className={classes.root} elevation={0} style={{ background: 'rgb(245,245,245)' }}>
        <img className={classes.image} src={typingGif} alt="" />
      </Paper>
    </div>
  );
}

export default withStyles(style)(Typing);
