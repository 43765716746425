import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import ImageAvatars from '../Avatar';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    width: 'fit-content',
    maxWidth: '60%',
    wordBreak: 'break-word'
  },
  left: {
    borderRadius: '20px 20px 20px 2px',
    color: '#000000',
    background: 'rgb(245, 245, 245)'
  },
  right: {
    borderRadius: '20px 20px 2px 20px',
    marginRight: 10,
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    color: theme.palette.text.primary
  },
  div: {
    display: 'flex'
  }
}))
  ;

export default function Bubble(props) {
  const classes = useStyles();
  let { position, text } = props;
  return (
    <div className={classes.div}>
      {position === 'right' ? '' : <ImageAvatars avatar={props.avatar} small={true} />}
      <Paper elevation={0} className={clsx(classes.root, position === 'right' ? classes.right : classes.left)}>
        <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </Typography>
      </Paper>
    </div>
  );
}
