import React from 'react';
import Bubble from '../Bubble';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { updateTextInput } from '../../actions/text.action';


const styles = () => ( {
  margin: {
    margin: 2,
    borderRadius: 20,
    textTransform: 'none'
  },

} );

class MultiSelect extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      buttons: [ 'one', 'two' ],
      show: true,
      selected: []
    };
  }


  selectOption = ( value ) => {
    if ( this.state.selected.includes( value ) ) {
      this.setState( {
        selected: this.state.selected.filter( item => item !== value )
      } );
      this.props.updateTextInput( {
        value: this.state.selected.filter( item => item !== value ).join( ', ' )
      } );
    } else {
      this.setState( {
        selected: [ ...this.state.selected, value ]
      } );
      this.props.updateTextInput( {
        value: [ ...this.state.selected, value ].join( ', ' )
      } );
    }
  };

  render() {
    const { classes, text } = this.props;
    return (
      <>
        <Bubble avatar={ this.props.avatar } theme={ this.props.theme } color={ this.props.color }
                text={ text || 'Choose your favourites' }/>
        <div style={ { marginLeft: 40 } }>
          { this.props.buttons.map( ( button, index ) => (
            <Button
              onClick={ () => this.selectOption( button.value ) }
              key={ index }
              variant={ this.state.selected.includes( button.value ) ? 'contained' : 'outlined' }
              color="primary"
              style={ { color: this.state.selected.includes( button.value ) ? this.props.design.textColor : this.props.design.themeColor } }
              aria-label="add"
              size={ 'medium' }
              className={ classes.margin }>
              <Typography>
                { button.value }
              </Typography>
            </Button> ) ) }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ( {
  design: state.design
} );

export default connect( mapStateToProps, {
  updateTextInput
} )( withStyles( styles )( MultiSelect ) );
