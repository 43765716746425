import React from 'react';
import { withStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import Bubble from '../Bubble';


const style = () => ( {
  root: {
    margin: 10,
    width: 'auto !important'
  }
} );

function Video( props ) {
  const { classes } = props;
  return (
    <div>
      <Bubble avatar={ props.avatar } text={ props.text } color={ props.color } theme={ props.theme }/>
      <ReactPlayer
        className={ classes.root }
        url={ props.url }
        controls={ true }
        playing={ false }
        width={ 'auto' }
        config={ {
          youtube: {
            playerVars: {
              origin: window.location.origin
            }
          }
        } }
      />
    </div>
  );
}

export default withStyles( style )( Video );
