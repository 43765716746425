import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Bubble from '../Bubble';


const useStyles = makeStyles( () => ( {
  card: {
    margin: 10,
    // marginLeft: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },

  title: {
    fontSize: 10
  }
} ) );

export default function ImageCard( props ) {
  const classes = useStyles();
  return (
    <>
      <Bubble avatar={ props.avatar } text={ props.text }/>
      <Card elevation={ 0 } className={ classes.card }>
        <CardMedia
          className={ classes.media }
          image={ props.image }
        />
      </Card>
    </>
  );
}
