import { UPDATE_META } from '../actions/actions';

export const metaReducer = ( state = {}, action ) => {
  if ( action.type === UPDATE_META ) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }

};
