import { UPDATE_ENVIRONMENT } from '../actions/actions';

const environmentReducer = (state = null, action) => {
  switch (action.type) {
    case UPDATE_ENVIRONMENT: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export { environmentReducer };
