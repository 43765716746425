export const NEW_MESSAGE = 'NEW_MESSAGE';
export const OLD_MESSAGES = 'OLD_MESSAGES';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_TEXT_INPUT = 'UPDATE_TEXT_INPUT';
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export const LOAD_FLOW = 'LOAD_FLOW';
export const UPDATE_META = 'UPDATE_META';
export const TYPING_START = 'TYPING_START';
export const TYPING_STOP = 'TYPING_STOP';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const LIVE_CHAT_NOTIFICATION = 'LIVE_CHAT_NOTIFICATION';
export const LOAD_DESIGN = 'LOAD_DESIGN';